import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
    data: {},
    processType: ''
  },
  mutations: {
    setProcessType (state, payload) {
      state.processType = payload
    }
  },
  actions: {
    setProcessType ({ commit }, type) {
      commit('setProcessType', type)
    }
  }
})

export default store;