<template>
  <header class="header">
    <div class="left">
      <a href="https://www.kernsystems.com" target="_blank" class="d-flex">
        <span class="icon"></span>
      </a>
      <div class="d-flex">
        <span class="first">Olli</span>
        <span class="second">by Kern Systems</span>
      </div>
    </div>
  </header>
</template>

<script>
export default {
  name: 'Header'
};
</script>

<style lang="scss" scoped>
.header {
  width: 100%;
  height: 61px;
  left: 0px;
  top: 37px;
  position: sticky;
  top: 0;
  z-index: 999999;

  background: var(--kern-blue);

  .icon {
    background-image: url('../assets/webclip.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    height: 33px;
    width: 33px;
    margin-right: 7px;
  }
  .left {
    display: flex;
    justify-content: space-between;
    min-height: 33px;
    align-items: center;
    padding: 14px 35px 0;

    a {
      text-decoration: none;
    }
    .first {
      width: 39px;
      height: 33px;
      left: 1160px;
      top: 51px;

      font-family: Source Sans Pro;
      font-style: normal;
      font-weight: 600;
      font-size: 26px;
      line-height: 33px;

      color: #f1505f;
      margin-right: 3px;
      display: inline-block;
    }

    .second {
      font-family: Source Sans Pro;
      font-style: normal;
      font-weight: normal;
      font-size: 10px;
      height: 20px;
      color: #ffffff;
      align-items: flex-end;
      display: flex;
      margin-top: 10px;
    }
  }
}
</style>
