<template>
  <header class="header">
    <div
      class="menu-bar pointer dropdown  dropdown-6"
      @click="setShowMenuList"
      @mouseover="!isMobile ? setShowMenuList : ''"
    >
      <div class="image-menu"></div>
    </div>
    <div
      :class="[
        'menu-list-wrapper dropdown_menu dropdown_menu--animated dropdown_menu-6',
        { close: closeEffect, open: showMenuList }
      ]"
      v-if="closeEffect || showMenuList"
    >
      <div class="menu-list" v-click-outside="hideShowMenuList">
        <div class="bold">kern systems</div>
        <div class="nav-block">
          <div class="bold">
            <router-link
              :to="{ name: 'Home' }"
            >
              Olli
            </router-link>
            <ul class="olli">
              <li class="normal pointer" @click="hideShowMenuList">
                <router-link
                  class="normal"
                  :to="{
                    name: 'Interactive',
                    query: {
                      type: 'interactive-demo'
                    }
                  }"
                >
                  interactive demo
                </router-link>
              </li>
              <li class="normal pointer" @click="hideShowMenuList">
                <router-link
                  class="normal"
                  :to="{ name: 'InputPage', query: { type: 'encode' } }"
                >
                  encode
                </router-link>
              </li>
              <li class="normal pointer" @click="hideShowMenuList">
                <router-link
                  class="normal"
                  :to="{ name: 'InputPage', query: { type: 'decode' } }"
                >
                  decode
                </router-link>
              </li>
              <li class="normal pointer" @click="hideShowMenuList">
                <router-link
                  class="normal"
                  :to="{
                    name: 'JobLookUp',
                    query: { type: 'find-previous-job' }
                  }"
                >
                  find previous job
                </router-link>
              </li>
              <li class="normal pointer" @click="hideShowMenuList">
                <router-link
                  class="normal"
                  :to="{ name: 'Feedback', query: { type: 'leave-feedback' } }"
                >
                  Leave feedback
                </router-link>
              </li>
            </ul>
          </div>
          <!-- <div class="normal pointer">
          <router-link
            class="normal"
            :to="{
              name: 'Home'
            }"
          >
            main website
          </router-link>
        </div>
        <div class="normal pointer">
          <router-link
            class="normal"
            :to="{
              name: 'Home'
            }"
          >
            contact
          </router-link>
        </div> -->
        </div>
      </div>
    </div>
    <div class="left">
      <div class="d-flex">
        <span class="first pointer" @click="$router.push('/')">Olli</span>
        <span class="separator">/</span>
        <span class="second">{{ type }}</span>
      </div>
    </div>
  </header>
</template>

<script>
import ClickOutside from 'vue-click-outside';
import { mapState } from 'vuex';
import { isMobile } from 'mobile-device-detect';
// import { bus } from '../../bus.js';

export default {
  name: 'Header',
  directives: {
    ClickOutside: ClickOutside.directive
  },
  data() {
    return {
      showMenuList: false,
      closeEffect: false,
      type: ''
    };
  },
  computed: {
    ...mapState(['processType']),
    _isMobile() {
      return isMobile;
    }
  },
  created() {
    this.setType();
  },
  methods: {
    setType() {
      const _type = (this.$route.query.type || '')
        .replaceAll('-', ' ')
        .replaceAll('_', ' ');
      if (_type) {
        this.type = _type;
      }
    },
    setShowMenuList() {
      this.$set(this, 'showMenuList', true);
    },
    hideShowMenuList() {
      this.showMenuList = false;
      this.closeEffect = true;
      setTimeout(() => {
        this.closeEffect = false;
      }, 300);
    }
  },
  watch: {
    processType: {
      immediate: true,
      handler: function(newValue) {
        if (!this.$route.query.type) {
          this.type = newValue.replaceAll('_', ' ').replaceAll('-', ' ');
        }
      }
    },
    $route: function() {
      this.showMenuList = false;
      this.setType();
    }
  }
};
</script>

<style lang="scss" scoped>
.header {
  width: 100vw;
  height: 61px;
  left: 0px;
  top: 37px;
  position: sticky;
  top: 0;
  z-index: 999999;

  background: var(--kern-blue);
  display: flex;

  @media (max-width: 420px) {
    height: 50px;
  }

  .menu-bar {
    height: inherit;
    width: 61px;
    background: var(--kern-red);
    position: relative;

    @media (max-width: 420px) {
      width: 50px;
    }
    .image-menu {
      position: absolute;
      height: 30px;
      width: 30px;
      background-image: url('../../assets/logo-menu-desktop.svg');
      background-size: center center;
      background-repeat: no-repeat;
      top: 16px;
      left: 16px;

      @media (max-width: 420px) {
        background-image: url('../../assets/menu-mobile.svg');
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
      }
    }
  }

  .menu-list {
    width: 292px;
    background: var(--kern-red);
    top: 62px;
    padding: 16px 20.67px 40px;

    @media (max-width: 420px) {
      width: 100%;
      top: 52px;
    }

    &-wrapper {
      width: 100vw;
      height: 100vh;
      top: 62px;
      position: absolute;
      @media (max-width: 420px) {
        top: 52px;
      }
    }

    .nav-block {
      margin-top: 14px;
      margin-left: 30px;
      color: #fff;

      a {
        color: #fff;
        text-decoration: none;

        &:hover {
          font-weight: bold;
          border-bottom: 1px solid #fff;
          padding-bottom: 2px;
        }
      }
    }

    .bold {
      font-family: Karla;
      font-style: normal;
      font-weight: bold;
      font-size: 20px;
      line-height: 23px;
      text-transform: uppercase;
      color: #fff;
    }

    li.bold {
      list-style-type: none;
    }

    ul.olli {
      margin-left: 30px;

      li::marker {
        background-color: #fff;
        font-weight: bold;
      }
    }
    div.normal {
      margin-top: 23px;
    }
    .normal {
      font-weight: normal;
      font-family: Karla;
      font-style: normal;
      font-size: 20px;
      line-height: 23px;
      text-transform: uppercase;
      margin-top: 10px;
    }
  }

  .icon {
    background-repeat: no-repeat;
    background-size: 100% 100%;
    height: 33px;
    width: 33px;
    margin-right: 7px;
  }
  .left {
    display: flex;
    justify-content: flex-start;
    min-height: 33px;
    align-items: center;
    margin-left: 17px;

    .first {
      height: 33px;
      left: 1160px;
      top: 51px;

      font-family: Karla;
      font-style: normal;
      font-weight: 600;
      font-size: 26px;
      line-height: 33px;

      color: #f1505f;
      margin-right: 3px;
      display: inline-block;
      text-transform: uppercase;
      margin-right: 10px;

      @media (max-width: 420px) {
        display: none;
      }
    }

    .separator {
      @media (max-width: 420px) {
        display: none !important;
      }
    }

    .separator,
    .second {
      font-family: Karla;
      margin-right: 10px;
      text-transform: uppercase;
      font-size: 26px;
      line-height: 33px;
      color: #ffffff;
      align-items: flex-end;
      display: flex;
    }
  }
}
.dropdown {
  perspective: 1000px;
  z-index: 100;

  &:hover {
    cursor: pointer;
  }
  &:hover .dropdown_menu li {
    display: block;
  }
}

.dropdown_menu-6 {
  height: 0 !important;
  &.open {
    animation: growDown 300ms ease-in-out forwards;
    transform-origin: top center;
    height: auto !important;
    display: block;
  }

  &.close {
    animation: close 300ms ease-in-out forwards;
  }
}

@keyframes growDown {
  0% {
    transform: scaleY(0);
  }
  80% {
    transform: scaleY(1.1);
  }
  100% {
    transform: scaleY(1);
  }
}

@keyframes close {
  0% {
    transform: rotateX(0deg);
  }

  100% {
    transform: rotateX(-90deg);
    display: none;
  }
}
</style>
