<template>
  <div data-app id="app">
    <component :is="layout">
      <router-view />
    </component>
  </div>
</template>

<script>
export default {
  name: 'App',

  data: () => ({
    //
  }),
  computed: {
    layout() {
      return this.$route.meta.layout || 'default-layout';
    }
  }
};
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro&display=swap');

:root {
  --kern-blue: #394459;
  --kern-red: #f2545b;
  --panel-bg-grey: #d2d8db;
  --light-grey-90B: #e6e6e6;
  --light-grey-57B: #919191;
  --primary-font-family: Source Sans Pro;
  --headline-font-family: Karla;
  --button-red: #f1505f;
}

html,
body {
  font-family: var(--primary-font-family);

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}

.pointer {
  cursor: pointer;
}

.d-none {
  display: none !important;
}

.d-flex {
  display: flex;
}

[v-cloak] {
  display: none;
}

.v-application .info {
  background-color: none !important;
}

#app {
  .theme--light.v-btn.v-btn--disabled.v-btn--has-bg,
  .theme--light.v-btn.v-btn--disabled {
    background-color: #727272 !important;
  }
}
</style>

<style lang="scss">
.tooltip {
  display: block !important;
  z-index: 10000;
  width: 259px;

  @media (max-width: 568px) {
    width: 206px;
  }

  @media (max-width: 320px) {
    left: -20px !important;
  }

  .tooltip-inner {
    padding-top: 6px;
    padding-left: 21px;
    padding-bottom: 9px;
    padding-right: 21px;
    background: #f2f2f6;
    border-radius: 5px;
    padding: 5px;

    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 21px;

    display: flex;
    align-items: center;

    color: #8a8a8a;

    @media (max-width: 320px) {
      align-items: left;
      font-size: 14px;
      line-height: 19px;
      max-width: 217.44px;
      width: 100%;
    }
  }

  .tooltip-arrow {
    width: 0;
    height: 0;
    border-style: solid;
    position: absolute;
    margin: 16px;
    border-color: #f2f2f6;
    z-index: 1;
    @media (max-width: 320px) {
      left: 172px !important;
    }
  }

  &[x-placement^='top'] {
    margin-bottom: 5px;

    .tooltip-arrow {
      border-width: 5px 5px 0 5px;
      border-left-color: transparent !important;
      border-right-color: transparent !important;
      border-bottom-color: transparent !important;
      bottom: -4px;
      left: calc(50% - 5px);
      margin-top: 0;
      margin-bottom: 0;
      transform: rotate(125deg);
    }
  }

  &[aria-hidden='true'] {
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.15s, visibility 0.15s;
  }

  &[aria-hidden='false'] {
    visibility: visible;
    opacity: 1;
    transition: opacity 0.15s;
  }
}

.theme--light.v-btn:focus::before {
  opacity: 0 !important;
}

.v-ripple__container {
  display: none !important;
}

.v-btn {
  box-shadow: none !important;
}

.v-tooltip__content {
  background: #394459 !important;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
  border-radius: 5px !important;
  width: 213px !important;
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 14px !important;
  line-height: 18px !important;
  display: flex;
  align-items: center;
  opacity: 1 !important;
  color: #ffffff !important;
}
</style>
