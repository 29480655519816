<template>
  <div class="default-layout">
    <Header />
    <div class="default-layout__container">
      <slot />
    </div>
  </div>
</template>

<script>
import Header from '@/components/interactive/Header.vue';

export default {
  name: 'InteractiveLayout',
  components: { Header }
};
</script>

<style lang="scss">
#nav {
  padding: 30px;
  a {
    font-weight: bold;
    color: #2c3e50;
    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
