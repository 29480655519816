import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/job-look-up',
    name: 'JobLookUp',
    component: () => import(/* webpackChunkName: "JobLookUp" */ '../views/JobLookUp.vue'),
    meta: {
      layout: 'interactive-layout'
    }
  },
  {
    path: '/input-page',
    name: 'InputPage',
    component: () => import(/* webpackChunkName: "InputPage" */ '../views/InputPage.vue'),
    meta: {
      layout: 'interactive-layout'
    }
  },
  {
    path: '/processing-page',
    name: 'ProcessingPage',
    component: () => import(/* webpackChunkName: "ProcessingPage" */ '../views/ProcessingPage.vue'),
    meta: {
      layout: 'interactive-layout'
    }
  },
  {
    path: '/output-page',
    name: 'OutputPage',
    component: () => import(/* webpackChunkName: "OutputPage" */ '../views/OutputPage.vue'),
    meta: {
      layout: 'interactive-layout'
    }
  },
  {
    path: '/interactive',
    name: 'Interactive',
    component: () => import(/* webpackChunkName: "Interactive" */ '../views/Interactive.vue'),
    meta: {
      layout: 'interactive-layout'
    }
  },
  {
    path: '/feedback',
    name: 'Feedback',
    component: () => import(/* webpackChunkName: "FeedbackPage" */ '../views/FeedbackPage.vue'),
    meta: {
      layout: 'interactive-layout'
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
