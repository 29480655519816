<template>
  <div class="home">
    <div class="info">
      <div class="title">
        <span class="header">Welcome To</span>
        <p class="brand">Olli</p>
        <span class="sub-header">
          A Digital Data to DNA Codec Processor
          <span class="popup" v-tooltip="msgTooltip"></span>
        </span>
      </div>
      <div class="actions">
        <v-btn
          class="red interactive"
          :to="{ name: 'Interactive', query: { type: 'interactive-demo' } }"
        >
          <div class="icon">
            <div class="interactive-icon"></div>
          </div>
          <span class="text">Interactive demo</span>
        </v-btn>
        <v-btn
          class="blue"
          :to="{ name: 'InputPage', query: { type: 'encode' } }"
          title="Encode your digital data into DNA sequences"
        >
          <div class="icon">
            <div class="encode-icon"></div>
          </div>
          <span class="text">Encode Your Data</span>
        </v-btn>
        <v-btn
          class="blue"
          :to="{ name: 'InputPage', query: { type: 'decode' } }"
          title="Decode your previously encoded DNA sequence back into digital data"
        >
          <div class="icon">
            <div class="decode-icon"></div>
          </div>
          <span class="text">Decode DNA</span>
        </v-btn>
        <v-btn
          class="grey"
          :to="{ name: 'JobLookUp', query: { type: 'find-previous-job' } }"
        >
          <div class="icon">
            <div class="find-icon"></div>
          </div>
          <span class="text">Find previous job</span>
        </v-btn>
        <v-btn
          class="white"
          :to="{ name: 'Feedback', query: { type: 'leave-feedback' } }"
        >
          <span class="text">Leave feedback</span>
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Home',
  data() {
    return {
      value: '',
      popupShow: false,
      msgTooltip:
        'Olli by Kern Systems allows users to convert digital data such as files and text into a set of DNA Sequences using Kern’s proprietary codec Primordio.'
    };
  },
  methods: {
    toggle() {
      this.popupShow = !this.popupShow;
    }
  }
};
</script>

<style lang="scss" scoped>
.home {
  height: calc(100vh - 61px);
  background-image: url('../assets/bg-home-1280.svg');
  background-position: 0 99px;
  background-size: auto 100%;

  @media (max-width: 768px) {
    background-image: url('../assets/bg-home-768.svg');
    background-size: auto 86%;
  }

  @media (max-width: 568px) {
    background-image: url('../assets/bg-home-568.svg');
    background-size: auto 75%;
    background-position: 0px 150px;
    overflow-y: auto;
    overflow-x: hidden;
  }

  .info {
    margin-left: 593px;
    display: flex;
    flex-direction: column;
    padding-top: 8vh;

    @media (min-width: 1090px) {
      margin-left: 488px;
    }

    @media (max-width: 1089px) {
      margin: auto;
      padding-top: 90px;

      .title {
        margin: auto;
      }
    }

    @media (max-width: 508px) {
      margin: 0 10px;
    }

    @media (max-width: 320px) {
      padding-top: 43px;
    }

    .title {
      @media (max-width: 768px) {
        max-width: 488px;
        width: 357px;
      }

      @media (max-width: 568px) {
        max-width: 488px;
        width: 100%;
      }
      @media (max-width: 320px) {
        width: 100%;
      }
    }

    .header {
      font-family: Source sans pro;
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 23px;
      letter-spacing: 1px;

      display: flex;
      align-items: center;

      color: var(--kern-blue);
      margin-left: 7px;

      @media (max-width: 768px) {
        margin-left: 0;
      }

      @media (max-width: 320px) {
        font-size: 14px;
        line-height: 18px;
        margin-left: 0;
      }
    }

    .brand {
      font-family: Source Sans Pro;
      font-style: normal;
      font-weight: bold;
      font-size: 96px;
      line-height: 96px;

      display: flex;
      align-items: center;
      letter-spacing: 10px;

      color: var(--kern-blue);

      @media (max-width: 768px) {
        margin-left: -6px;
      }

      @media (max-width: 500px) {
        margin-left: -4px;
      }

      @media (max-width: 320px) {
        font-size: 70px;
        line-height: 70px;
        margin-left: 0;
      }
    }
    .sub-header {
      font-family: Source Sans Pro;
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 23px;
      margin-left: 7px;
      letter-spacing: 1px;

      display: flex;
      align-items: center;

      color: var(--kern-blue);

      @media (max-width: 768px) {
        margin-left: 0;
      }

      @media (max-width: 361px) {
        margin-left: 0 !important;
        letter-spacing: normal;
      }

      .popup {
        background-image: url('../assets/info-popup.svg');
        width: 20px;
        height: 20px;

        opacity: 0.6;
        margin-left: 10px;
        cursor: pointer;
      }

      @media (max-width: 320px) {
        font-size: 16px;
        line-height: 20px;
        margin-left: 10px;
      }
    }

    .actions {
      margin-top: 54px;

      @media (max-width: 768px) {
        margin: 54px auto 0;
      }

      @media (max-width: 1089px) {
        margin: 54px auto 0;
      }

      @media (min-width: 569px) and (max-width: 768px) {
        width: 357px;
        max-width: 100vh;
      }

      @media (max-width: 361px) {
        margin: 37px 0 0;
      }
    }
    ::v-deep .v-btn {
      width: 310px;
      height: 60px;
      margin-left: 20px;
      border-radius: 6px;
      padding: 0;
      box-shadow: none;

      display: flex;
      align-items: center;

      @media (max-width: 1090px) {
        width: 357px;
      }

      @media (max-width: 568px) {
        max-width: 488px;
        width: calc(100vw - 20px);
      }

      @media (max-width: 768px) {
        margin: 0 0 23px !important;
      }

      @media (max-width: 320px) {
        width: 300px;
        margin: 0 0 23px !important;
      }

      .text {
        text-transform: uppercase;
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 26px;
        color: #ffffff;
      }

      &__content {
        justify-content: left !important;
      }
    }
    .blue {
      background: var(--kern-blue);
      margin-bottom: 23px;
      margin-left: 20px;

      &:hover {
        background: #394459;
        transition: background ease 0.3s;
      }

      @media (max-width: 768px) {
        margin: 0;
      }
    }
    .red {
      background: #f1505f;
      margin-bottom: 26px;

      &:hover {
        background: #f1505f;
        transition: background ease 0.3s;
      }
    }
    .grey {
      background: #707071;
      margin-bottom: 26px;
      @media (max-width: 320px) {
        // padding: 0 28px 0 18px;
      }
    }
    .white {
      background: #fff;
      border: 2px solid #707071;

      .text {
        color: #707071;
        margin-left: 78px;

        @media (max-width: 320px) {
          margin-left: 68px;
        }
      }
    }

    .icon {
      display: flex;
      justify-content: center;
      width: 78px;

      @media (max-width: 1090px) {
        width: 85px !important;
      }

      @media (max-width: 768px) {
        width: 78px !important;
      }

      @media (max-width: 320px) {
        width: 68px !important;
      }
    }
    .encode-icon {
      background-image: url('../assets/encode-icon.svg');
      background-repeat: no-repeat;
      width: 28px;
      height: 28px;
    }

    .decode-icon {
      background-image: url('../assets/decode-icon.svg');
      background-repeat: no-repeat;
      width: 38px;
      height: 27.43px;
    }

    .find-icon {
      background-image: url('../assets/find-icon.svg');
      background-repeat: no-repeat;
      width: 25px;
      height: 27.34px;
    }

    .interactive-icon {
      background-image: url('../assets/interactive-demo.svg');
      background-repeat: no-repeat;
      width: 38px;
      height: 38px;
    }
  }
}
</style>
