import { bus } from '../bus.js';
let webSocketsService = {}
const VUE_APP_WS = process.env.VUE_APP_WS || ''

webSocketsService.install = function (Vue, options) {
  let ws = { ...options }
  let reconnectInterval = options?.reconnectInterval || 1000

  Vue.prototype.$webSocketsConnect = (path = '') => {
    const url = VUE_APP_WS + path
    ws = new WebSocket(url)

    ws.onopen = (event) => {
      // Restart reconnect interval
      reconnectInterval = options.reconnectInterval || 1000
      handleNotification('onopen', event)
    }

    ws.onmessage = (event) => {
      // New message from the backend - use JSON.parse(event.data)
      handleNotification('onmessage', event)
    }

    ws.onclose = (event) => {
      if (event) {
        // Event.code 1000 is our normal close event
        if (event.code !== 1000) {
          let maxReconnectInterval = options.maxReconnectInterval || 3000
          setTimeout(() => {
            if (reconnectInterval < maxReconnectInterval) {
              // Reconnect interval can't be > x seconds
              reconnectInterval += 1000
            }
            // Vue.prototype.$webSocketsConnect(path)
          }, reconnectInterval)
        }
      }
    }

    ws.onerror = (error) => {
      console.log(error)
      ws.close()
    }

    return ws
  }

  Vue.prototype.$webSocketsDisconnect = (ws) => {
    // Our custom disconnect event
    ws.close()
  }

  Vue.prototype.$webSocketsSend = (data) => {
    ws.send(data)
  }

  /*
    Here we write our custom functions to not make a mess in one function
  */
  function handleNotification (type, event) {
    bus.$emit(type, event)
  }
}

export default webSocketsService